import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./portfolio.css";
import Loading from "./loader1";
import { Navigation } from "swiper/modules";

export default function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      {loading ? (
        <div className="swipperr">
          <div className="swipperr12">
            <div className="lbox">
              <Loading />
            </div>
          </div>
        </div>
      ) : (
        <div className="swipperr">
          <Swiper
            rewind={true}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              {" "}
              <div className="swiper_image">
                <img
                  src="/images/awards/SCA_1.jpg"
                  alt="award 1"
                  className="swiper-image"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper_image">
                <img
                  src="/images/awards/AC_2.jpg"
                  alt="award 2"
                  className="swiper-image"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper_image">
                <img
                  src="/images/awards/AC_3.jpg"
                  alt="award 3"
                  className="swiper-image"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper_image">
                <img
                  src="/images/awards/EWA_4.jpg"
                  alt="award 4"
                  className="swiper-image"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper_image">
                <img
                  src="/images/awards/EWA_5.jpg"
                  alt="award 5"
                  className="swiper-image"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper_image">
                <img
                  src="/images/awards/LAA_6.jpg"
                  alt="award 6"
                  className="swiper-image"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper_image">
                <img
                  src="/images/awards/OTS_7.jpg"
                  alt="award 7"
                  className="swiper-image"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper_image">
                <img
                  src="/images/awards/EWA_8.jpg"
                  alt="award 8"
                  className="swiper-image"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper_image">
                <img
                  src="/images/awards/FPMA_9.jpg"
                  alt="award 9"
                  className="swiper-image"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper_image">
                <img
                  src="/images/awards/MP_10.jpg"
                  alt="award 10"
                  className="swiper-image"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      )}
    </>
  );
}
