/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from "react";
import "./portfolio.css";
import HeaderStatic from "./headerStatic";
import Footer from "./footer";
import Accordion from "./accordion";
import Python from "./svg/python.svg";
import BC from "./svg/Baas.svg";
import GC from "./svg/gc.svg";
import HTML5 from "./svg/html5.svg";
import CSS3 from "./svg/css3.svg";
import JS from "./svg/javascript.svg";
import React1 from "./svg/react.svg";
import API from "./svg/API.svg";
import BC1 from "./svg/bc1.svg";
import Eth from "./svg/ethereum.svg";
import Scm from "./svg/scm.svg";
import Nft from "./svg/nft.svg";
import Ai from "./svg/ai.svg";
import Open from "./svg/open.svg";
import Newtab from "./svg/newtab.svg";
import Loading from "./loader";

const Skills = () => {
  const [loading, setLoading] = useState(true);
  const handlelogout = (e) => {};
  const [isloading, setisloading] = useState(false);
  function funcopen() {
    let an = document.getElementById("type_ani");
    an.style.display = "block";
    let vm = document.getElementById("view_more1");
    vm.classList.toggle("visiblecard");
    vm.classList.toggle("hiddencard");
    let sl = document.getElementById("view_less1");
    sl.classList.toggle("hiddencard");
    let card1 = document.getElementById("hiddencard1");
    let card2 = document.getElementById("hiddencard2");
    let card3 = document.getElementById("hiddencard3");
    let card4 = document.getElementById("hiddencard4");
    let card5 = document.getElementById("hiddencard5");
    let card6 = document.getElementById("hiddencard6");
    card1.classList.toggle("visiblecard");
    card2.classList.toggle("visiblecard");
    card3.classList.toggle("visiblecard");
    card4.classList.toggle("visiblecard");
    card5.classList.toggle("visiblecard");
    card6.classList.toggle("visiblecard");
  }

  function funcclose() {
    let vm = document.getElementById("view_more1");
    let tn = document.getElementById("type_ani");
    tn.style.display = "none";
    vm.classList.toggle("visiblecard");
    vm.classList.toggle("hiddencard");
    let sl = document.getElementById("view_less1");
    sl.classList.toggle("hiddencard");
    let card1 = document.getElementById("hiddencard1");
    let card2 = document.getElementById("hiddencard2");
    let card3 = document.getElementById("hiddencard3");
    let card4 = document.getElementById("hiddencard4");
    let card5 = document.getElementById("hiddencard5");
    let card6 = document.getElementById("hiddencard6");
    card1.classList.toggle("visiblecard");
    card2.classList.toggle("visiblecard");
    card3.classList.toggle("visiblecard");
    card4.classList.toggle("visiblecard");
    card5.classList.toggle("visiblecard");
    card6.classList.toggle("visiblecard");
  }

  useEffect(() => {
    setisloading(true);
    window.scrollTo(0, 0);
    setisloading(false);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="about_container1">
          <HeaderStatic message="Skills" />
          <Loading />
        </div>
      ) : (
        <div className="skills_container">
          <HeaderStatic message="Skills" />

          {isloading == false ? (
            <div className="Accordion_123">
              <h1 className="skills_title cssanimation sequence fadeInTop">
                SKILLS
              </h1>
              <Accordion />
            </div>
          ) : (
            ""
          )}
          <div className="Certifications">
            <h1 className="certi_title cssanimation sequence fadeInBottom">
              CERTIFICATIONS
            </h1>

            <div className="cards">
              <div className="card">
                <div className="card_fh clr1">
                  <div class="overlay">
                    <a
                      href="https://verify.openedg.org/?id=k0Nz.yhRy.evLz"
                      target="_blank"
                      class="text"
                    >
                      CERTIFICATE &nbsp;
                      <img
                        src={Open}
                        alt="view certificate"
                        className="openn"
                      />
                    </a>
                  </div>

                  <img src={Python} alt="Python" className="immmg" />
                </div>

                <div className="card_sh">
                  PCEP-Certified Entry-Level Python Programmer Certification
                  <p>Vendor: Python Institute</p>
                </div>
              </div>

              <div className="card">
                <div className="card_fh clr2">
                  <div class="overlay">
                    <a
                      href="https://www.credential.net/17115cc6-e1f9-4d18-bd60-939da5a14eb7#gs.6w2aer"
                      target="_blank"
                      class="text"
                    >
                      CERTIFICATE &nbsp;
                      <img
                        src={Open}
                        alt="view certificate"
                        className="openn"
                      />
                    </a>
                  </div>

                  <img src={BC} alt="blockchain" className="imamg" />
                </div>

                <div className="card_sh">
                  Certified Blockchain Developer
                  <p>Vendor: Blockchain Council</p>
                </div>
              </div>

              <div className="card">
                <div className="card_fh clr3">
                  <div class="overlay">
                    <a
                      href="https://www.credly.com/badges/ba179e44-26e6-42a6-92df-fa00974b6417/public_url"
                      target="_blank"
                      class="text"
                    >
                      CERTIFICATE &nbsp;
                      <img
                        src={Open}
                        alt="view certificate"
                        className="openn"
                      />
                    </a>
                  </div>

                  <img src={GC} alt="google cloud" className="immmg1 gcpp" />
                </div>

                <div className="card_sh">
                  Google Cloud Certified Associate Cloud Engineer Certification
                  <p>Vendor: Google</p>
                </div>
              </div>

              <div className="card">
                <div className="card_fh clr4">
                  <div class="overlay">
                    <a
                      href="https://www.udemy.com/certificate/UC-78c26f8b-6332-407d-86e6-b194135ed4cf/"
                      target="_blank"
                      class="text"
                    >
                      CERTIFICATE &nbsp;
                      <img
                        src={Open}
                        alt="view certificate"
                        className="openn"
                      />
                    </a>
                  </div>

                  <img src={HTML5} alt="html5" className="immmg" />

                  <img src={CSS3} alt="css3" className="immmg" />
                </div>

                <div className="card_sh">
                  Build Responsive Real-World Websites with HTML and CSS
                  <p>Vendor: Udemy</p>
                </div>
              </div>

              <div className="card">
                <div className="card_fh clr5">
                  <div class="overlay">
                    <a
                      href="https://www.udemy.com/certificate/UC-1fcbd721-9c7a-4b5c-8414-18196289b309/"
                      target="_blank"
                      class="text"
                    >
                      CERTIFICATE &nbsp;
                      <img
                        src={Open}
                        alt="view certificate"
                        className="openn"
                      />
                    </a>
                  </div>

                  <img src={JS} alt="Javascript" className="immmg" />
                </div>
                <div className="card_sh">
                  JavaScript Fundamentals<p>Vendor: Udemy</p>
                </div>
              </div>

              <div className="card">
                <div className="card_fh clr6">
                  <div class="overlay">
                    <a
                      href="https://www.udemy.com/certificate/UC-561f253b-7260-499f-9ab6-38f484d0931a/"
                      target="_blank"
                      class="text"
                    >
                      CERTIFICATE &nbsp;
                      <img
                        src={Open}
                        alt="view certificate"
                        className="openn"
                      />
                    </a>
                  </div>

                  <img src={React1} alt="ReactJS" className="immmg" />
                </div>

                <div className="card_sh">
                  Hello React Training Bootcamp
                  <p>Vendor: Udemy</p>
                </div>

                <div className="card hiddencard" id="hiddencard1"></div>

                <div className="card_fh clr7">
                  <div class="overlay">
                    <a
                      href="https://www.google.com"
                      target="_blank"
                      class="text"
                    >
                      CERTIFICATE &nbsp;
                      <img
                        src={Open}
                        alt="view certificate"
                        className="openn"
                      />
                    </a>
                  </div>

                  <img src={API} alt="apl" className="immmg" />
                </div>

                <div className="card_sh">
                  Understanding APIs and RESTful APIs Crash Course
                  <p>Vendor: Udemy</p>
                </div>
              </div>

              <div className="card hiddencard" id="hiddencard2">
                <div className="card_fh clra">
                  <div class="overlay">
                    <a
                      href="https://www.udemy.com/certificate/UC-1d331886-44b0-43c8-b7c2-30afe0266605/"
                      target="_blank"
                      class="text"
                    >
                      CERTIFICATE &nbsp;
                      <img
                        src={Open}
                        alt="view certificate"
                        className="openn"
                      />
                    </a>
                  </div>

                  <img src={BC1} alt="blockchain" className="immmg" />
                </div>
                <div className="card_sh">
                  Blockchain and Bitcoin Fundamentals<p>Vendor: Udemy</p>
                </div>
              </div>

              <div className="card hiddencard" id="hiddencard3">
                <div className="card_fh clr9">
                  <div class="overlay">
                    <a
                      href="https://www.udemy.com/certificate/UC-903c9b89-97e2-47ed-8b07-da1cc7019449/"
                      target="_blank"
                      class="text"
                    >
                      CERTIFICATE &nbsp;
                      <img
                        src={Open}
                        alt="view certificate"
                        className="openn"
                      />
                    </a>
                  </div>

                  <img src={Eth} alt="ethereum" className="immmg" />
                </div>

                <div className="card_sh">
                  Ethereum and Solidity: The Complete Developer's Guide
                  <p>Vendor: Udemy</p>
                </div>

                <div className="card hiddencard" id="hiddencard4"></div>

                <div className="card fh clrie">
                  <div class="overlay">
                    <a
                      href="https://www.google.com"
                      target="_blank"
                      class="text"
                    >
                      CERTIFICATE &nbsp;
                      <img
                        src={Open}
                        alt="view certificate"
                        className="openn"
                      />
                    </a>
                  </div>

                  <img src={Scm} alt="sca" className="img" />
                </div>

                <div className="card_sh">
                  Blockchain in Supply Chain Management <p>Vendor: Udemy</p>
                </div>
              </div>

              <div className="card hiddencard" id="hiddencard5">
                <div className="card_fh clr11">
                  <div class="overlay">
                    <a
                      href="https://www.udemy.com/certificate/UC-460f6b25-7a9e-4f8e-b178-fd56fef50551/"
                      target="_blank"
                      class="text"
                    >
                      CERTIFICATE &nbsp;
                      <img
                        src={Open}
                        alt="view certificate"
                        className="openn"
                      />
                    </a>
                  </div>

                  <img src={Nft} alt="nft" className="immmg" />
                </div>

                <div className="card_sh">
                  NFT Fundamentals <br></br>(Buy, Create and Sell NFTs)
                  <p>Vendor: Udemy</p>
                </div>
              </div>

              <div className="card hiddencard" id="hiddencard6">
                <div className="card_fh clr12">
                  <div class="overlay">
                    <a
                      href="https://www.udemy.com/certificate/UC-dee50966-5f78-46ec-9177-7e03b7d3ab66/"
                      target="_blank"
                      class="text"
                    >
                      CERTIFICATE &nbsp;
                      <img
                        src={Open}
                        alt="view certificate"
                        className="openn"
                      />
                    </a>
                  </div>

                  <img src={Ai} alt="AI" className="immmg" />
                </div>

                <div className="card_sh">
                  Master Generative AI: Automate Content Effortlessly with AI
                  <p>Vendor: Udemy</p>
                </div>
              </div>
            </div>

            <div
              id="view_more1"
              className="view_more visiblecard"
              onClick={funcopen}
            >
              <h1>View more</h1>
            </div>

            <div id="type_ani" className="typecard">
              <p class="line-1 anim-typewriter">
                Check out my recent certifications on{" "}
                <a
                  href="https://www.linkedin.com/in/sridharsakthivel"
                  className="certi_lnkd"
                  target="_blank"
                >
                  LinkedIn
                  <img src={Newtab} alt="view certificate" className="newtab" />
                </a>
              </p>
            </div>

            <div
              id="view_less1"
              className="view_less hiddencard"
              onClick={funcclose}
            >
              <h1>View less</h1>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default Skills;
