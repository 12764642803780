import React, { useState, useEffect } from "react";
import EnglishResume from "../resume/sridharsakthivel.pdf";
import EnglishResumeImage1 from "../resume/sridharsakthivel_1.jpg";
import EnglishResumeImage2 from "../resume/sridharsakthivel_2.jpg";
import "./portfolio.css";
import HeaderStatic from "./headerStatic";
import Footer from "./footer";
import Loading from "./loader";

const Resume = () => {
  const [isloading, setisloading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <div className="about_container1">
          <HeaderStatic message="resume" />
          <Loading />
        </div>
      ) : (
        <div className="res_container">
          <HeaderStatic message="resume" />

          {isloading === false ? (
            <div className="res_Accordion_123">
              <h1 className="exp_title cssanimation sequence fadeInTop">
                RESUME
              </h1>
              <div className="resumecontainer">
                {" "}
                <embed src={EnglishResume} className="pdfviewerr lrr" />
                <img
                  src={EnglishResumeImage1}
                  alt="EnglishResumeImage"
                  className="srr"
                ></img>
                <img
                  src={EnglishResumeImage2}
                  alt="EnglishResumeImage"
                  className="srr"
                ></img>
                {/* <a
                  href={EnglishResume}
                  download="SridharSakthivel_CV"
                  target="_blank"
                  rel="noreferrer"
                  className="dwnbtn"
                >
                  <button class="buttoncv">Download</button>
                </a> */}
              </div>
            </div>
          ) : (
            ""
          )}
          <Footer />
        </div>
      )}
    </>
  );
};

export default Resume;
