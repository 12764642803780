import React, { useState, useEffect, useRef } from "react";
import "./portfolio.css";
import Copyright from "./svg/copyright.svg";

const Footer = () => {
  const dataFetchedRef = useRef(false);

  let [currentyear, setcurrentyear] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    setcurrentyear(new Date().getFullYear());

    dataFetchedRef.current = true;

    setIsLoading(false);
  }, []);

  return (
    <div className="footer_container">
      {isLoading == false ? (
        <div className="first_footer">
          <h1 className="footer_cpyright">
            <img src={Copyright} alt="development" className="cpyright" />
            &nbsp;Sridhar Sakthivel {currentyear}
          </h1>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Footer;
