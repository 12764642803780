import React, { useState } from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import About from "./Portfolio/about";
import Skills from "./Portfolio/skills";
import Experience from "./Portfolio/experience";
import Contact from "./Portfolio/contact";
import Resume from "./Portfolio/resume";

function App() {
  return (
    <div className="app_container">
      <Routes>
        <Route path="" element={<About />} />
        <Route path="/Skills" element={<Skills />} />
        <Route path="/Experience" element={<Experience />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Resume" element={<Resume />} />
        <Route path="*" element={<Navigate to="" replace />} />
      </Routes>
    </div>
  );
}

export default App;
